<template>
  <v-card>
    <template v-if="isActive">
      <v-card-text class="d-flex">
        <div
          class="mr-5"
          style="width:350px"
        >
          <SelectStock
            @symbol-changed="setSymbol"
          />
        </div>
        <div style="width:110px">
          <v-select
            v-model="resolution"
            :items="resolutionOptions"
            dense
            outlined
            hide-details
          />
        </div>
      </v-card-text>
      <v-card-text
        class="pa-4 pt-0"
      >
        <TradingChart
          ref="chart"
          :symbol="symbol"
          :resolution="resolution"
          @timeRangeChange="timeRangeChange"
        />
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text>
        <div
          class="d-flex align-center justify-center"
          style="min-height: 550px"
        >
          <span class="text-subtitle-1">
            Vui lòng nâng cấp tài khoản để được cấp quyền xem
          </span>
        </div>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>

import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import TradingChart from '../components/TradingChart.vue'
import SelectStock from '../components/SelectStock.vue'

Vue.use(new VueSocketIO({
  connection: SocketIO('https://tradingviewrealtime.vps.com.vn', { forceNew: true, transports: ['websocket'] }),
}))

const fetchRange = 500 * 24 * 3600
const now = Math.floor(Date.now() / 1000)

export default {
  components: {
    SelectStock,
    TradingChart,
  },
  data() {
    return {
      from: null,
      to: null,
      symbol: null,
      lockFetch: false,
      fetching: false,
      resolution: '1D',
      resolutionOptions: [
        { text: '5 phút', value: '5' },
        { text: '15 phút', value: '15' },
        { text: '30 phút', value: '30' },
        { text: '1 giờ', value: '60' },
        { text: '1 ngày', value: '1D' },
      ],
    }
  },
  computed: {
    isActive() {
      const user = this.$store.getters['user/user'] || {}

      return user.premium_features?.includes('trading-chart') || false
    },
  },
  watch: {
    symbol(symbol, oldSymbol) {
      this.leave(oldSymbol)
      this.reloadData()
      this.join(symbol)
    },
    resolution() {
      this.reloadData()
    },
  },
  created() {
    this.reloadData()
  },
  sockets: {
    stock(data) {
      this.$refs.chart.pushData(data)
    },
  },
  methods: {
    timeRangeChange({ from }) {
      if (from <= 80) {
        this.to = this.from
        this.from -= fetchRange
        if (this.from < 0) return
        this.fetchHistoryData(false)
      }
    },
    fetchHistoryData(resetData) {
      if (this.lockFetch || this.fetching || !this.symbol) return

      this.fetching = true

      const params = {
        symbol: this.symbol, resolution: this.resolution, from: this.from, to: this.to,
      }
      this.$http.get('/api/trading-history', { params })
        .then(resp => {
          if (resp.s === 'no_data') {
            this.lockFetch = true

            return
          }
          this.$refs.chart.setRawData(resp.data, resetData)
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.fetching = false
        })
    },
    reloadData() {
      this.lockFetch = false
      this.from = now - fetchRange
      this.to = now
      this.fetchHistoryData(true)
    },
    setSymbol(value) {
      this.symbol = value
    },
    join(symbol) {
      const message = {
        action: 'join',
        list: `CHART.${symbol}`,
      }
      this.$socket.emit('regs', JSON.stringify(message))
    },
    leave(symbol) {
      const message = {
        action: 'leave',
        list: `CHART.${symbol}`,
      }
      this.$socket.emit('regs', JSON.stringify(message))
    },
    // addRealtimeData() {
    //   const data = newData.shift()
    //   console.log(data)
    //   this.$refs.chart.pushData(data)
    // },
  },
}

</script>
